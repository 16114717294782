.bottomfooter{

  color: #000000;
  border-radius: 10px;
  width: 100%;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  align-content: center; 
}
.Container{
  width: 25%;
  float: left;
}

/* Media Query*/


@media only screen and (min-width: 300px) {
  .Container{
    width: 100%;
    float: none;
  }
}
@media only screen and (min-width: 360px) {
}
@media only screen and (min-width: 477px) {
  .Container{
    width: 50%;
    float: left;
  }
}
@media only screen and (min-width: 597px) {
}
@media only screen and (min-width: 747px) {
  
}
@media only screen and (min-width: 897px) {
  .Container{
    width: 25%;
    float: left;
  }
}
@media only screen and (min-width: 1047px) {
}
@media only screen and (min-width: 1197px) {
}
@media only screen and (min-width: 1347px) {
}
@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1645px) {
}
@media only screen and (min-width: 1797px) {
}
@media only screen and (min-width: 1947px) {
}